/*
  This class extends the below mixins
  see https://www.typescriptlang.org/docs/handbook/mixins.html
*/
import {
  PublicUserMethods,
  PublicClubMethods,
  PublicPostMethods,
  UserMethods,
  PostMethods,
  ClubMethods,
  MediaItemMethods,
  AdminMethods,
  AiMethods,
} from './mixapi_mixins'

class Api {}

// eslint does not like that we redeclare, too bad!!
// eslint-disable-next-line no-redeclare
interface Api
  extends PublicUserMethods,
    PublicClubMethods,
    PublicPostMethods,
    UserMethods,
    PostMethods,
    ClubMethods,
    MediaItemMethods,
    AiMethods,
    AdminMethods {}

applyMixins(Api, [
  PublicUserMethods,
  PublicClubMethods,
  PublicPostMethods,
  UserMethods,
  PostMethods,
  ClubMethods,
  MediaItemMethods,
  AdminMethods,
  AiMethods,
])

function applyMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
          Object.create(null)
      )
    })
  })
}

export default Api
