

























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import {
  IMediaItemCoordinates,
  ICropMediaItem,
  IMediaCellImageReplacement,
  IMediaItemUpdate,
} from '@/interfaces'
import { IMediaItems, IUser } from '@/_store/models'
//@ts-ignore
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { CellEditFileTypes } from '@/constants'
import $ from 'jquery'

@Component({
  components: {
    Cropper,
  },
  methods: {
    ...mapActions(['GetPostForEdit', 'CropMediaItem']),
    ...mapGetters(['StateUser']),
  },
})
export default class CellEditModal extends Vue {
  GetPostForEdit!: Function
  CropMediaItem!: Function
  StateUser!: Function

  @Prop()
  setSelectedMediaItem!: Function

  @Prop()
  selectedMediaItem!: IMediaItems

  @Prop()
  postId!: number

  @Prop()
  replaceCellMediaItem!: Function

  // Watch selectedMediaItem because when the modal is closed and a different
  // cell is opened, we need to update the title to the newly selected item
  // or the title will display the other cells value
  @Watch('selectedMediaItem')
  selectedMediaItemChanged(item: IMediaItems) {
    this.newTitle = item.title
  }

  cropImg = ''
  data = null
  acceptableFiletypes = CellEditFileTypes
  showCropper: boolean = true
  croppingImage: boolean = false
  changingCellImage: boolean = false
  imageChangedEvent: string = ''
  croppedImage: string = ''
  cropperAspectRatio: string = '4/3'
  imageToShow: any
  savingSettings = false
  selectedMediaItemNewCoords: IMediaItemCoordinates = {}
  newTitle = this.cellTitle

  get cellTitle() {
    return this.selectedMediaItem.title
  }

  get user() {
    const user: IUser = this.StateUser()
    return user
  }

  get imgSrc() {
    return this.selectedMediaItem?.image_original_520_url
  }

  setNewCoords(cp: any) {
    const x = Math.round(cp.coordinates.left)
    const y = Math.round(cp.coordinates.top)
    const x2 = Math.round(cp.coordinates.left + cp.coordinates.width)
    const y2 = Math.round(cp.coordinates.top + cp.coordinates.height)
    this.selectedMediaItemNewCoords = {
      x: x,
      y: y,
      x2: x2,
      y2: y2,
    }
  }

  async fileChangeEvent(event: any) {
    if (event === null || event === undefined) {
      return
    }
    this.showCropper = false
    this.changingCellImage = true
    this.imageChangedEvent = ''
    if (this.selectedMediaItem) {
      const replacement: IMediaCellImageReplacement = {
        postId: this.postId,
        mediaItemId: this.selectedMediaItem.id,
        position: this.selectedMediaItem.position,
        fileOrData: event.target.files[0],
      }
      const response = await this.$api.replaceMediaItemCellImage(replacement)
      if (response.status == 200) {
        //await this.GetPostForEdit(this.postId)
        document
          .getElementById('cropperImageThumb')
          ?.setAttribute('src', response.data.image_thumb_url || '')
        this.replaceCellMediaItem(response.data)
      } else {
        alert('Something happened when trying to swap image. Contact Support.')
      }
    }
    this.showCropper = true
    this.changingCellImage = false
    document.querySelector('.modal-backdrop')?.remove()
    $('#cropModalBtn').click()
  }

  showImageSwap() {
    if (typeof this.selectedMediaItem === 'undefined') {
      return false
    }
    // allow user to choose new image for vids, links
    const mt: number = this.selectedMediaItem?.media_type
    if (mt === 2) {
      return false
    }
    return true
  }

  setAspectRatio() {
    let aspectRatio
    if (this.showImageSwap()) {
      switch (this.selectedMediaItem?.position) {
        case 1:
          aspectRatio = 4 / 3
          break
        case 2:
        case 3:
          aspectRatio = 0.77 / 1
          break
        default:
          aspectRatio = 4 / 3
          break
      }
    }
    return aspectRatio
  }

  async updateCellSettings() {
    if (this.selectedMediaItem) {
      this.savingSettings = true
      const params: IMediaItemUpdate = {
        id: this.selectedMediaItem.id,
        post_id: this.postId,
        // @ts-ignore
        title: this.newTitle,
      }
      await this.$api.updateCellSettings(params)
      // update upstream
      this.selectedMediaItem.title = this.newTitle
      this.savingSettings = false
    }
  }

  async cropMediaItemImage() {
    if (this.selectedMediaItem) {
      this.croppingImage = true
      const data: ICropMediaItem = {
        postId: this.selectedMediaItem.post_id,
        mediaItemId: this.selectedMediaItem.id,
        params: this.selectedMediaItemNewCoords,
      }
      await this.CropMediaItem(data)
      // document
      //   .getElementById('cropperImageThumb')
      //   ?.setAttribute('src', this.selectedMediaItem.image_thumb_2x_url || '')
      // this.croppingImage = false
      // document.querySelector('.modal-backdrop')?.remove()
      // document.getElementById('cropModalBtn')?.click()
      location.reload() // reload page after, fixes a lot of bugs
    }
  }
}
