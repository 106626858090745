import {
  IMediaItemCoordinates,
  IMediaItemUpdate,
  IMediaCellImageReplacement,
  IPostMediaItem,
  IPostMediaItemWebUrl,
  IPostMediaItemYoutube,
  IPostMediaItemSketchFab,
  IPostMediaItemCellViaAiReplace,
  IPostMediaItemCellViaAiReplacePdf,
} from '@/interfaces'
import store from '@/store'
import { EnvironmentModule } from '@/_store/modules'
import axios from 'axios'

class MediaItemMethods {
  async getJsonpData(embededId: string | number) {
    const response = await axios.get(
      `${EnvironmentModule.widgetJsonpUrl}/embeds/${embededId}-v2.jsonp`,
      {
        withCredentials: false,
        validateStatus: function(status) {
          return status < 500 // Resolve only if the status code is less than 500
        },
      }
    )
    return response
  }

  async updateCellSettings(params: IMediaItemUpdate) {
    const response = await axios.put(
      `/api/v1/posts/${params.post_id}/media_items/${params.id}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async replaceMediaItemCellImage(data: IMediaCellImageReplacement) {
    const formData: FormData = new FormData()
    formData.append('post_id', data.postId.toString())
    formData.append('position', data.position.toString())
    formData.append('image_only', 'true')
    formData.append('file', data.fileOrData, data.fileOrData.name)
    let response
    if (data.mediaItemId) {
      response = await axios.put(
        `/api/v1/media_items/${data.mediaItemId.toString()}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
          },
        }
      )
      return response
    } else {
      response = await axios.post(`/api/v1/media_items`, formData, {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      })
    }
    return response
  }

  async replaceMediaItemImageWithUrl(data: IPostMediaItemWebUrl) {
    const response = await axios.post(`/api/v1/media_items`, data, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async replaceMediaItemImageWithYoutubeUrl(data: IPostMediaItemYoutube) {
    const response = await axios.post(`/api/v1/media_items`, data, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async replaceMediaItemImageWithSketchFabUrl(data: IPostMediaItemSketchFab) {
    const response = await axios.post(`/api/v1/media_items`, data, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async replaceMediaItem(data: IPostMediaItem) {
    const formData: FormData = new FormData()
    formData.append('post_id', data.postId.toString())
    formData.append('position', data.position.toString())
    formData.append('media_type', data.mediaType.toString())
    formData.append('file', data.fileOrData, data.fileOrData.name)
    const response = await axios.post(`/api/v1/media_items`, formData, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async aiReplaceMediaItemCell(data: IPostMediaItemCellViaAiReplace) {
    const formData: FormData = new FormData()
    formData.append('post_id', data.postId.toString())
    formData.append('position', data.position.toString())
    formData.append('media_type', data.mediaType.toString())
    formData.append('file', data.file, data.fileName)
    formData.append('ai_prompt', data.ai_prompt)
    const response = await axios.post(`/api/v1/media_items`, formData, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async aiReplaceMediaItemCellPdf(data: IPostMediaItemCellViaAiReplacePdf) {
    const formData: FormData = new FormData()
    formData.append('post_id', data.postId.toString())
    formData.append('position', data.position.toString())
    formData.append('ai_prompt', data.ai_prompt.toString())
    formData.append('text_result', data.text_result.toString())
    const response = await axios.post(`/api/v1/ai/set_cell_pdf`, formData, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async proxyAiImageDownload(imgUrl: string) {
    const data = {
      imgUrl: imgUrl,
    }
    const response = await axios.post(`/api/v1/ai/proxy_img_request`, data, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
      responseType: 'blob',
    })
    return response
  }

  async cropMediaItemImage(mediaItemId: number, params: IMediaItemCoordinates) {
    const response = await axios.post(
      `/api/v1/media_items/${mediaItemId}/crop`,
      params,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async pollMediaItem(postId: number, position: number) {
    const response = await axios.get(
      `/api/v1/media_items/encoding_status?position=${position}&post_id=${postId}`,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
        validateStatus: function(status) {
          return status < 500 // Resolve only if the status code is less than 500
        },
      }
    )
    return response
  }
}

export default MediaItemMethods
