import router from '@/router'
import Api from '@/_services/api'
import {
  IPostSearch,
  IUserLogin,
  IPost,
  IUserSearch,
  IAccountUserFull,
  IAccount,
  IClub,
  IMyProfile,
  IClubImgCropUpdate,
} from '../models'

import { ICropMediaItem, IMediaCellImageReplacement } from '@/interfaces'

const API = new Api()

const state = {
  expiry: null,
  user: null,
  posts: null,
  loading: true,
  post: null,
  myClubs: null,
  myClubsLoading: false,
  memberClubs: null,
  clubForEdit: null,
  users: null,
  userForEdit: null,
  accounts: null,
  accountForEdit: null,
  myProfile: null,
  loggingIn: false,
}
const getters = {
  isAuthenticated: (state: any) => !!state.user,
  isSiteAdmin: (state: any) => !!state.user?.admin,
  StatePosts: (state: any) => state.posts,
  StateEditPost: (state: any) => state.post,
  StateMyClubsLoading: (state: any) => state.myClubsLoading,
  StateMyClubs: (state: any) => state.myClubs,
  StateMemberClubs: (state: any) => state.memberClubs,
  StateMemberClubsLoading: (state: any) => state.memberClubsLoading,
  StateUser: (state: any) => state.user,
  StateExpiry: (state: any) => state.expiry,
  StateLoading: (state: any) => state.loading,
  StateClubForEdit: (state: any) => state.clubForEdit,
  StateUsersList: (state: any) => state.users,
  StateUserForEdit: (state: any) => state.userForEdit,
  StateAccountsList: (state: any) => state.accounts,
  StateAccountForEdit: (state: any) => state.accountForEdit,
  StateMyProfile: (state: any) => state.myProfile,
  StateIsLoggingIn: (state: any) => state.loggingIn,
}
const actions = {
  async LogIn({ commit }: any, user: IUserLogin) {
    await commit('setUser', null)
    await commit('setExpiry', null)
    const response = await API.loginUser(user)
    if (response.status === 401) {
      return response
    } else {
      await commit('setUser', response.data)
      await commit('setExpiry', new Date().getTime() + 86400000)
      return response
    }
  },

  async SetNewExpiry({ commit }: any) {
    await commit('setExpiry', null)
    await commit('setExpiry', new Date().getTime() + 86400000)
  },

  async SetLoggingIn({ commit }: any, val: boolean) {
    await commit('setLoggingin', val)
  },

  async GetMyProfile({ commit }: any) {
    commit('setLoading', true)
    commit('setMyProfile', null)
    const response = await API.getMyProfile()
    commit('setMyProfile', response.data)
    commit('setLoading', false)
  },

  async UpdateMyProfile({ commit }: any, profile: IMyProfile) {
    commit('setLoading', true)
    commit('setMyProfile', null)
    const response = await API.updateMyProfile(profile)
    commit('setMyProfile', response.data)
    commit('setLoading', false)
  },

  async GetPosts({ commit }: any, params: IPostSearch) {
    commit('setLoading', true)
    commit('setPosts', null)
    const response = await API.getMyPosts(params)
    commit('setPosts', response.data)
    commit('setLoading', false)
  },

  async GetPostForEdit({ commit }: any, id: string | number) {
    commit('setLoading', true)
    commit('setPost', null)
    const response = await API.getMyPost(id)
    commit('setPost', response.data)
    commit('setLoading', false)
  },

  async UpdateMyPost({ commit }: any, post: IPost) {
    const response = await API.updateMyPost(post)
    commit('setPost', response.data)
  },

  async DeleteMyPost({ commit }: any, id: string | number) {
    await API.deleteMyPost(id)
    router.push('/posts/my-posts')
    // await dispatch('GetPosts')
    commit('setPost', null)
  },
  // eslint-disable-next-line no-empty-pattern
  async ReplaceMediaItemCellImage(
    { commit }: any,
    mr: IMediaCellImageReplacement
  ) {
    await API.replaceMediaItemCellImage(mr)
    commit('setLoading', true)
    commit('setPost', null)
    const response = await API.getMyPost(mr.postId)
    commit('setPost', response.data)
    commit('setLoading', false)
  },

  async CropMediaItem({ commit }: any, cmi: ICropMediaItem) {
    await API.cropMediaItemImage(cmi.mediaItemId, cmi.params)
    // commit('setLoading', true)
    const response = await API.getMyPost(cmi.postId)
    await commit('setPost', response.data)
    // commit('setLoading', false)
  },

  async GetMyClubs({ commit }: any) {
    commit('setMyClubsLoading', true)
    commit('setMyClubs', null)
    const response = await API.getMyClubs()
    commit('setMyClubs', response.data)
    commit('setMyClubsLoading', false)
  },

  async GetMemberClubs({ commit }: any) {
    commit('setMemberClubsLoading', true)
    commit('setMemberClubs', null)
    const response = await API.getMemberClubs()
    commit('setMemberClubs', response.data)
    commit('setMemberClubsLoading', false)
  },

  async GetClubForEdit({ commit }: any, params: [0, true]) {
    const load = params[1]
    if (load) {
      commit('setLoading', true)
    }
    commit('setClubForEdit', null)
    const response = await API.getClub(params[0])
    commit('setClubForEdit', response.data)
    commit('setLoading', false)
  },

  async DeleteClub({ commit }: any, id: string) {
    commit('setLoading', true)
    commit('setClubForEdit', null)
    await API.deleteClub(id)
    commit('setLoading', false)
  },

  async ReplaceClubImg({ commit }: any, params: IClubImgCropUpdate) {
    await API.replaceClubImage(params)
    commit('setLoading', true)
    commit('setClubForEdit', null)
    const response = await API.getClub(params.clubId)
    commit('setClubForEdit', response.data)
    commit('setLoading', false)
  },

  async UpdateClub({ commit }: any, club: IClub) {
    const response = await API.updateClub(club)
    commit('setClubForEdit', response.data)
  },

  // Admin stuff
  async GetUsers({ commit }: any, params: IUserSearch) {
    commit('setLoading', true)
    commit('setUsers', null)
    const response = await API.getUsers(params)
    commit('setUsers', response.data)
    commit('setLoading', false)
  },

  async GetUserForEdit({ commit }: any, id: number) {
    commit('setLoading', true)
    commit('setUserForEdit', null)
    const response = await API.getUser(id)
    commit('setUserForEdit', response.data)
    commit('setLoading', false)
  },

  async UpdateAccountUser({ commit }: any, user: IAccountUserFull) {
    const response = await API.updateAccountUser(user)
    commit('setUserForEdit', response.data)
  },

  ResetUserForEdit({ commit }: any) {
    commit('setUserForEdit', null)
  },

  async GetAccounts({ commit }: any, params: IUserSearch) {
    commit('setLoading', true)
    commit('setAccounts', null)
    const response = await API.getAccounts(params)
    commit('setAccounts', response.data)
    commit('setLoading', false)
  },

  async GetAccountForEdit({ commit }: any, id: number) {
    commit('setLoading', true)
    commit('setAccountForEdit', null)
    const response = await API.getAccount(id)
    commit('setAccountForEdit', response.data)
    commit('setLoading', false)
  },

  async UpdateAccount({ commit }: any, account: IAccount) {
    const response = await API.updateAccount(account)
    commit('setAccountForEdit', response.data)
  },

  ResetAccountForEdit({ commit }: any) {
    commit('setAccountForEdit', null)
  },

  // Other
  async LogOut({ commit }: any) {
    commit('logout')
    if (router.currentRoute?.path !== '/') {
      router.push('/')
    }
  },

  async SetLoading({ commit }: any, val: boolean) {
    commit('setLoading', val)
  }
}
const mutations = {
  setExpiry(state: any, expiry: number) {
    state.expiry = expiry
  },

  setUser(state: any, user: any) {
    state.user = user
  },

  setLoggingin(state: any, val: any) {
    state.loggingIn = val
  },

  setPosts(state: any, data: any) {
    state.posts = data
  },

  setPost(state: any, data: any) {
    state.post = data
  },

  setMyClubs(state: any, myClubs: any[]) {
    state.myClubs = myClubs
  },

  setLoading(state: any, loading: boolean) {
    state.loading = loading
  },

  logout(state: any, val: any = null) {
    state.user = val
    state.expiry = val
    state.user = val
    state.posts = val
    state.loading = true
    state.post = val
    state.myClubs = val
    state.myClubsLoading = false
    state.memberClubs = val
    state.clubForEdit = val
    state.users = val
    state.userForEdit = val
    state.accounts = val
    state.accountForEdit = val
    state.myProfile = val
    state.loggingIn = false
  },

  setMyClubsLoading(state: any, loading: boolean) {
    state.myClubsLoading = loading
  },

  setMemberClubs(state: any, memberClubs: any[]) {
    state.memberClubs = memberClubs
  },

  setMemberClubsLoading(state: any, loading: boolean) {
    state.memberClubsLoading = loading
  },

  setClubForEdit(state: any, data: any) {
    state.clubForEdit = data
  },

  setUserForEdit(state: any, data: any) {
    state.userForEdit = data
  },

  setUsers(state: any, data: any) {
    state.users = data
  },

  setAccountForEdit(state: any, data: any) {
    state.accountForEdit = data
  },

  setAccounts(state: any, data: any) {
    state.accounts = data
  },

  setMyProfile(state: any, data: any) {
    state.myProfile = data
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
