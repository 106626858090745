


























































































































































































































































































































import { IUser } from '@/_store/models'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class MobileNav extends Vue {
  @Prop()
  readonly postCreating!: boolean

  @Prop()
  readonly user!: IUser

  @Prop()
  readonly host!: string

  @Prop()
  readonly bannerText!: string

  @Prop()
  readonly bannerClass!: string

  @Prop()
  readonly useMailChimp!: boolean

  @Prop()
  readonly isLoggedIn!: boolean

  @Prop()
  readonly isSuper!: boolean

  @Prop()
  logOut!: Function

  @Prop()
  createNewPost!: Function
}
