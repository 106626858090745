import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)
import truncate from 'truncate'
Vue.prototype.truncate = truncate
import moment from 'moment'
Vue.prototype.moment = moment
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)
import Grid from 'vue-js-grid'
Vue.use(Grid)

import axios, { AxiosInstance } from 'axios'
import { EnvironmentModule } from '@/_store/modules'
const apiUrl = EnvironmentModule.apiUrl
axios.defaults.baseURL = apiUrl
axios.defaults.headers = {
  'Content-Type': 'application/json',
}
axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      if (store.getters.StateIsLoggingIn) {
        originalRequest._retry = true
        return error
      } else {
        store.dispatch('LogOut')
        return router.push('/')
      }
    }

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true
      return router.push('/403')
    }

    if (error.response.status === 406 && !originalRequest._retry) {
      originalRequest._retry = true
      // Just return the response, we may need to inspect before throwing error
      return error.response
    }

    if (error.response.status === 422 && !originalRequest._retry) {
      originalRequest._retry = true
      return error
    }

    if (error.response.status === 404 && !originalRequest._retry) {
      originalRequest._retry = true
      return router.push('/404')
    }
    alert('There was a system error. Please contact support!')
    return router.push('/')
  }
})
Vue.prototype.$http = axios
declare module 'vue/types/vue' {
  /* eslint-disable-next-line*/
  interface Vue {
    $axios: AxiosInstance
  }
}

// Import third part stuff
import tpImportComponents from './thirdparty-imports'
tpImportComponents()

Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})

Vue.use(VModal, {
  dynamicDefault: { draggable: true, resizable: false, adaptive: true },
})

Vue.config.productionTip = false

// Register problematic components
// for some reason these components always
// throw error "did you register the component correctly?"
// so we register them at app level
import importComponents from './component-imports'
importComponents()

// Global Variabes and prototypes
import prototypesInit from './prototypes'
prototypesInit()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
