import { render, staticRenderFns } from "./AdminAccountView.vue?vue&type=template&id=92f5d6ae&"
import script from "./AdminAccountView.vue?vue&type=script&lang=ts&"
export * from "./AdminAccountView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports