





























































































































































































































































































































































































import { IAiDescribeImage, IPost } from '@/_store/models'
import {
  IPostMediaItemCellViaAiReplace,
  IPostMediaItemCellViaAiReplacePdf,
} from '@/interfaces'
import { IAlert } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CellPositions, MediaTypes } from '@/constants'

@Component({})
export default class AiModal extends Vue {
  prompt = ''
  resultsReceived = false
  results = ''
  loading = false
  replaceBtnText = 'Replace Article'
  appendBtnText = 'Append to Article'
  activeTab = 'text'
  cellPositions = [
    { value: null, text: 'Select a Cell to assign.' },
    ...CellPositions,
  ]
  selectedCell: null | number = null
  assignBtnDisabled = false
  oldPrompt = ''

  @Prop()
  sendAlertMessageUp!: Function

  @Prop()
  post!: IPost

  get cellNotSelected() {
    return this.selectedCell === null ? true : false
  }

  get coinBadgeColor() {
    const val = this.post.ai_credits_available
    switch (true) {
      case val == 0:
        return 'danger'
      case val >= 1 && val <= 10:
        return 'warning text-dark'
      case val > 10:
        return 'primary'
      default:
        return 'primary'
    }
  }

  changeType(val: string) {
    this.activeTab = val
    this.prompt = ''
    this.resultsReceived = false
    this.results = ''
  }

  reset(type = 'text') {
    this.prompt = ''
    this.oldPrompt = ''
    this.activeTab = type
    this.results = ''
    this.resultsReceived = false
    this.replaceBtnText = 'Replace Article'
    this.appendBtnText = 'Append to Article'
    this.selectedCell = null
  }

  replaceContent() {
    if (
      confirm(
        'Are you sure? This will replace content to the end of your article!\nAn alternate option is to select and copy only the portion you want and paste into the Article manually.'
      ) == true
    ) {
      const elm = document.getElementById('post-ql-editor')
      const child = elm?.querySelector('.ql-editor')
      const btn = document.getElementById('articleReplace')
      if (child && btn) {
        child.innerHTML = ''
        child.insertAdjacentHTML('beforeend', this.results)
        this.replaceBtnText = 'Inserted!'
        const modal = document.getElementById('aiPostModalClose')
        if (modal) {
          modal.click()
        }
      }
    } else {
      return
    }
  }

  appendContent() {
    if (
      confirm(
        'Are you sure? This will add content to the end of your article!\nAn alternate option is to select and copy only the portion you want and paste into the Article manually.'
      ) == true
    ) {
      const elm = document.getElementById('post-ql-editor')
      const child = elm?.querySelector('.ql-editor')
      const btn = document.getElementById('articleInsert')
      if (child && btn) {
        child.insertAdjacentHTML('beforeend', this.results)
        this.appendBtnText = 'Inserted!'
        const modal = document.getElementById('aiPostModalClose')
        if (modal) {
          modal.click()
        }
      }
    } else {
      return
    }
  }

  async setCellPdf() {
    if (
      !this.cellNotSelected &&
      this.selectedCell !== null &&
      confirm(
        `Are you sure? This will replace cell ${this.selectedCell}'s content!`
      ) == true
    ) {
      this.loading = true
      this.assignBtnDisabled = true
      try {
        const replacement: IPostMediaItemCellViaAiReplacePdf = {
          postId: this.post.id,
          position: this.selectedCell,
          text_result: this.results,
          ai_prompt: this.prompt,
        }
        const response = await this.$api.aiReplaceMediaItemCellPdf(replacement)
        if (response.status === 202) {
          this.loading = false
          this.assignBtnDisabled = false
          window.location.reload()
        } else {
          this.loading = false
          this.assignBtnDisabled = false
        }
      } catch (e) {
        this.loading = false
        this.sendAlert()
      }
    }
  }

  async replaceCellImage() {
    if (
      !this.cellNotSelected &&
      this.selectedCell !== null &&
      confirm(
        `Are you sure? This will replace cell ${this.selectedCell}'s content!`
      ) == true
    ) {
      const imgDiv = document.getElementById('aiImageResult')
      if (imgDiv !== null) {
        const imgSrc = imgDiv.getAttribute('src')
        if (imgSrc !== null) {
          this.loading = true
          this.assignBtnDisabled = true
          try {
            const image = await this.$api.proxyAiImageDownload(imgSrc)
            if (image.status === 200) {
              const replacement: IPostMediaItemCellViaAiReplace = {
                postId: this.post.id,
                position: this.selectedCell,
                file: image.data,
                fileName: 'open-ai-result.png',
                mediaType: MediaTypes.MEDIA_TYPE_IMAGE,
                ai_prompt: this.prompt,
              }
              const response = await this.$api.aiReplaceMediaItemCell(
                replacement
              )
              if (response.status === 202) {
                this.loading = false
                this.assignBtnDisabled = false
                window.location.reload()
              } else {
                this.loading = false
                this.assignBtnDisabled = false
              }
            }
          } catch (e) {
            this.loading = false
            this.sendAlert()
          }
        }
      }
    }
  }

  async sendPrompt() {
    this.loading = true
    const res = await this.$api.relayPrompt({
      prompt: this.prompt,
    })
    this.loading = false
    if (res.status == 200) {
      this.resultsReceived = true
      this.results = res.data.content
      this.post.ai_credits_available = res.data.coin_balance
    } else if (res.status == 406) {
      this.sendAlert(
        'Insufficient Balance! You do not have enough Mixcoins to perform this Action. Please purchase more.'
      )
    } else {
      this.sendAlert()
    }
  }

  async createImage() {
    this.loading = true
    this.oldPrompt = this.prompt
    const res = await this.$api.createImage({
      prompt: this.prompt,
    })
    this.loading = false
    if (res.status == 200) {
      this.resultsReceived = true
      this.results = res.data.url
      this.post.ai_credits_available = res.data.coin_balance
    } else if (res.status == 406) {
      this.sendAlert(
        'Insufficient Balance! You do not have enough Mixcoins to perform this Action. Please purchase more.'
      )
    } else {
      this.sendAlert()
    }
  }

  async uploadImage(event: any) {
    this.prompt = ''
    this.resultsReceived = false
    this.results = ''
    this.loading = true
    const upload: IAiDescribeImage = {
      file: event.target.files[0],
    }
    const res = await this.$api.describeImage(upload)
    this.loading = false
    if (res.status == 200) {
      this.resultsReceived = true
      this.results = res.data.content
    } else {
      this.sendAlert()
    }
  }

  sendAlert(text = 'There was an issue with your request. Contact support') {
    const alert: IAlert = {
      message: text,
      alertType: 'danger',
      timeout: 10000,
    }
    this.sendAlertMessageUp(alert)
  }
}
