




















































































































































































































































































































































































































































































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { IPost, IMyClub, IUser } from '@/_store/models'
import { IAlert } from '@/types'
// Components
import Places from '@/components/ui/Places.vue'
import { Meta } from '@sophosoft/vue-meta-decorator'
import $ from 'jquery'
import { KeenFilter } from '@/interfaces'

@Component({
  components: { Places },
  computed: {
    ...mapGetters(['StateEditPost', 'StateLoading', 'StateUser']),
  },
  methods: {
    ...mapActions(['GetPostForEdit', 'UpdateMyPost', 'DeleteMyPost']),
    ...mapMutations(['setPost']),
  },
})
export default class EditPost extends Vue {
  @Meta
  getMetaInfo() {
    return {
      title: 'Edit Post',
    }
  }

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  StateEditPost!: IPost
  StateUser!: IUser
  StateLoading!: boolean
  GetPostForEdit!: Function
  UpdateMyPost!: Function
  DeleteMyPost!: Function
  setPost!: Function

  deleting: boolean = false
  submitted: boolean = false
  error: undefined
  current_hover: undefined
  dropped_cell = {}
  hideControls: undefined
  showDrop: undefined
  reording_posts = false
  exampleArticle: string =
    '<p><strong style="color: rgb(230, 0, 0);">*REMOVE ME*</strong></p><p><span class="ql-size-large">I am your example article!</span></p><p>Here are some tips:</p><ul><li>Use the toolbar above to format your text.</li><li>Please note that copying from a document and pasting here may strip out some formatting.</li><li>When creating links, be sure to append "http://" or "https://" (if not already present) to ensure the link properly functions.</li><li>Images are not supported in the article.</li></ul><p>Have Fun!</p>'
  savingSettings: boolean = false
  memberClubs: IMyClub[] = []
  memberClubsLoading = false
  selectedClubIds: number[] = []
  cloning = false
  clonedImg: string | null | undefined = ''
  clonedTitle = ''
  clonedPostId: null | number = null
  postViews = 0
  toggleSearch: boolean = false

  get isPostPrivate() {
    return this.post.visibility === 'private'
  }

  get loading() {
    return this.StateLoading
  }

  get post() {
    return this.StateEditPost
  }

  get isMobile() {
    const yes = this.$isMobile()
    return yes
  }

  resetClonedModal() {
    this.clonedTitle = ''
    this.clonedPostId = null
    this.clonedImg = ''
    // @ts-ignore
    const elm = $('#clonedPostModal')
    // @ts-ignore
    elm.modal('hide')
  }

  async clonePost() {
    this.cloning = true
    const response = await this.$api.clonePost(this.post.id)
    if (response.status === 201) {
      this.cloning = false
      const data: IPost = { ...response.data }
      if (data.media_items && data.media_items?.length > 0) {
        this.clonedImg = data.media_items[0].image_thumb_2x_url
      }
      this.clonedTitle = data.title
      this.clonedPostId = data.id

      this.$scrollToTop()
      // @ts-ignore
      const elm = $('#clonedPostModal')
      // @ts-ignore
      elm.modal('show')
    } else {
      const alert: IAlert = {
        message: 'There was an error cloning post! Please contact support.',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
    this.cloning = false
  }

  async getMyClubs() {
    this.memberClubsLoading = true
    const response = await this.$api.getMyEditableClubs()
    if (response.status === 200) {
      this.memberClubs = response.data
    } else {
      const alert: IAlert = {
        message:
          'There was an error loading your clubs! Please contact support.',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
    this.memberClubsLoading = false
  }

  async getPostViews(postId: string) {
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'eq',
        property_value: postId,
      },
    ]
    await this.$keenApi
      .analysis('count', 'Post Loaded', 'this_10_years', 'id', 'id', filter)
      .then((resp: any) => {
        resp.map((obj: any) => (this.postViews += obj.result))
      })
  }

  async getPost() {
    await this.GetPostForEdit(this.$route.params.id)
    this.selectedClubIds = this.post.groups.map((club: IMyClub) => club.id)
    await this.getPostViews(this.$route.params.id)
  }

  onEditorChange(val: string) {
    // @ts-ignore
    this.post.post_description_rt = val.html
  }

  async submit() {
    this.submitted = true
    let selectClubs
    if (this.selectedClubIds.length > 0) {
      selectClubs = this.memberClubs.filter((club: IMyClub) =>
        this.selectedClubIds.includes(club.id)
      )
    }
    const updatedPost: IPost = {
      ...this.post,
      groups: selectClubs ? selectClubs : [],
    }
    await this.UpdateMyPost(updatedPost)
    const currentPasswdElm = document.getElementById('div-disabled-span')
    if (currentPasswdElm) {
      currentPasswdElm.innerHTML = this.post.plain_text_password
        ? this.post.plain_text_password
        : ''
    }

    const alert: IAlert = {
      message: 'Post Updated',
      alertType: 'success',
    }
    this.sendAlertMessageUp(alert)
    this.$scrollToTop()
    this.submitted = false
  }

  async deletePost() {
    if (
      confirm(
        'Are you sure you want to delete this post?\nThis action is irreversible.'
      )
    ) {
      this.deleting = true
      await this.DeleteMyPost(this.post.id)
    } else {
      return
    }
  }

  mounted() {
    this.getPost()
    this.getMyClubs()
  }
}
