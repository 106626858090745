
























































































































































































































































































































































import { Vue, Component, Emit } from 'vue-property-decorator'
import { Meta } from '@sophosoft/vue-meta-decorator'
import { IAccount, IUser } from '@/_store/models'
import { mapActions, mapGetters } from 'vuex'
import { IAlert } from '@/types'

@Component({
  computed: {
    ...mapGetters(['StateLoading', 'StateAccountForEdit', 'StateUser']),
  },
  methods: {
    ...mapActions([
      'GetAccountForEdit',
      'UpdateAccount',
      'ResetAccountForEdit',
    ]),
  },
})
export default class AdminAccountView extends Vue {
  GetAccountForEdit!: Function
  StateUser!: IUser
  StateLoading!: boolean
  UpdateAccount!: Function
  ResetAccountForEdit!: Function
  StateAccountForEdit!: IAccount

  accountId = this.$route.params.id
  submitted = false

  accounts: any[] = []
  accountSearch = ''
  selectedAccount: string = ''
  selectpicker: any
  accountTypes = ['basic', 'pro', 'enterprise']

  @Meta
  getMetaInfo() {
    return {
      title: 'Edit User',
    }
  }

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  get loading() {
    return this.StateLoading
  }

  get account() {
    return this.StateAccountForEdit
  }

  get accountUsers() {
    return this.account.users
  }

  async getAccount() {
    await this.GetAccountForEdit(this.accountId)
  }

  async submit() {
    this.submitted = true
    await this.UpdateAccount(this.account)
    const alert: IAlert = {
      message: 'Account Updated',
      alertType: 'success',
    }
    this.sendAlertMessageUp(alert)
    this.submitted = false
  }

  created() {
    this.getAccount()
  }

  beforeDestroy() {
    this.ResetAccountForEdit()
  }
}
