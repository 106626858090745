import store from '@/store'
import axios from 'axios'
import { IAiDescribeImage, IAiPrompt } from '@/_store/models'

class AiMethods {
  async relayPrompt(data: IAiPrompt) {
    const response = await axios.post(`/api/v1/ai/relay_prompt`, data, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async createImage(data: IAiPrompt) {
    const response = await axios.post(`/api/v1/ai/create_image`, data, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async describeImage(data: IAiDescribeImage) {
    const formData: FormData = new FormData()
    formData.append('file', data.file, data.file.name)
    let response = await axios.post(`/api/v1/ai/describe_image`, formData, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })

    return response
  }
}

export default AiMethods
