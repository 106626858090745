



















































































import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class ProtectionModal extends Vue {
  @Prop({ default: '' })
  readonly resourceHash!: string

  @Prop({ default: '' })
  readonly resourcePassword!: string

  @Prop()
  readonly unlock!: Function

  password = ''

  error = ''

  get useMailChimp() {
    return this.$useMailChimp
  }

  submitPassword() {
    if (this.password !== this.resourcePassword) {
      this.error = 'Incorrect password. Please try again'
      return
    }
    this.setSession()
    this.unlock()
  }

  clearError() {
    this.error = ''
  }

  focusMyElement() {
    // @ts-ignore
    this.$refs.focusThis.focus()
  }

  setSession() {
    const now = new Date()
    const ttl = 8.64e7 // milliseconds i.e. 24 hours
    const item = {
      resource: this.resourceHash,
      expiry: now.getTime() + ttl,
    }

    const obj = localStorage.getItem('mx-pp')
    let newList = []
    if (obj !== null && obj !== undefined) {
      newList = JSON.parse(obj)
      newList.push(item)
    } else {
      newList.push(item)
    }
    localStorage.setItem('mx-pp', JSON.stringify(newList))
  }

  checkSession() {
    const obj = localStorage.getItem('mx-pp')
    if (obj != null && obj !== undefined) {
      const list = JSON.parse(obj)
      const now = new Date()
      // Check each resource is list to see if expired and remove it to relock
      if (list.length > 0) {
        list.forEach((elem: any, index: any) => {
          if (
            elem.resource === this.resourceHash &&
            now > new Date(elem.expiry)
          ) {
            list.splice(index, 1)
            console.log(list)
            localStorage.setItem('mx-pp', JSON.stringify(list))
            return
          } else if (elem.resource === this.resourceHash) {
            this.unlock()
            return
          }
        })
      }
    }
  }

  mounted() {
    this.checkSession()
    this.$bvModal.show('protection-modal')
  }
}
